var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"750","max-width":"100%","persistent":"","scrollable":""},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',{staticClass:"mx-auto",class:{
      'w-carglass-form-modal': true,
      'w-carglass-form-modal--mobile': _vm.$voicer.isMobile,
    }},[_c('v-toolbar',{staticClass:"color: #fbde51",attrs:{"flat":""}},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.open = false}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("close")])],1)],1),_vm._v(" "),_c('v-img',{attrs:{"src":_vm.$voicer.isMobile
          ? require('~/assets/images/popupmobilemuscu.png')
          : require('~/assets/images/popupdesktopmuscu.png'),"alt":"image emission","max-height":_vm.$voicer.isMobile === false ? 410 : undefined}}),_vm._v(" "),_c('div',{staticClass:"w-block__post-featured"},[_c('v-sheet',{staticClass:"w-block__pre-featured__topblock pt-2 pb-2",attrs:{"color":"#e84b23","dark":"","tile":""}},[_c('v-container',{staticClass:"pa-1"},[_c('div',{staticClass:"subtitle font-weight-bold"},[_vm._v("\n            Lancement prévu dans\n            "+_vm._s(_vm.eventCountdown)+"..\n          ")])])],1)],1),_vm._v(" "),_c('v-card-text',{staticClass:"w-carglass-form-modal__body2",class:{
        'pa-2 subtitle-2': _vm.$voicer.isMobile === false,
        'pa-1 subtitle-2': _vm.$voicer.isMobile === true,
      }},[_c('h3',[_vm._v("Réveil Musculaire Géant !")]),_vm._v(" "),_c('div',[_vm._v("Mercredi 2 avril 2025")]),_vm._v(" "),_c('div',{staticClass:"body-2"},[_vm._v("\n        Votre Réveil Musculaire Géant arrive bientôt ! Soyez prêts à vous\n        connecter tous ensemble.\n      ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }