<template>
  <v-dialog v-model="open" width="750" max-width="100%" persistent scrollable>
    <v-card
      class="mx-auto"
      :class="{
        'w-carglass-form-modal': true,
        'w-carglass-form-modal--mobile': $voicer.isMobile,
      }"
    >
      <v-toolbar flat class="color: #fbde51">
        <v-spacer />
        <v-btn icon small @click.stop="open = false">
          <v-icon color="black">close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-img
        :src="
          $voicer.isMobile
            ? require('~/assets/images/popupmobilemuscu.png')
            : require('~/assets/images/popupdesktopmuscu.png')
        "
        alt="image emission"
        :max-height="$voicer.isMobile === false ? 410 : undefined"
      />
      <div class="w-block__post-featured">
        <v-sheet
          class="w-block__pre-featured__topblock pt-2 pb-2"
          color="#e84b23"
          dark
          tile
        >
          <v-container class="pa-1">
            <div class="subtitle font-weight-bold">
              Lancement prévu dans
              {{ eventCountdown }}..
            </div>
          </v-container>
        </v-sheet>
      </div>
      <v-card-text
        class="w-carglass-form-modal__body2"
        :class="{
          'pa-2 subtitle-2': $voicer.isMobile === false,
          'pa-1 subtitle-2': $voicer.isMobile === true,
        }"
      >
        <h3>Réveil Musculaire Géant !</h3>
        <div>Mercredi 2 avril 2025</div>
        <div class="body-2">
          Votre Réveil Musculaire Géant arrive bientôt ! Soyez prêts à vous
          connecter tous ensemble.
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import localstore from 'store'
function formatTimeCountdown(seconds) {
  seconds = seconds || 1
  seconds = Number(seconds)
  seconds = Math.abs(seconds)
  const d = Math.floor(seconds / (3600 * 24))
  const h = Math.floor((seconds % (3600 * 24)) / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor((seconds % 60) * 1)
  const parts = []
  if (d > 0) {
    parts.push(d + ' jour' + (d > 1 ? 's' : ''))
  }
  if (h > 0) {
    parts.push(h + ' heure' + (h > 1 ? 's' : ''))
  }
  if (m > 0) {
    parts.push(m + ' minute' + (m > 1 ? 's' : ''))
  }
  if (s > 0) {
    parts.push(s + ' seconde' + (s > 1 ? 's' : ''))
  }
  return parts.join(', ')
}
export default {
  data() {
    const lastOpen = localstore.get('exc_dialog_last_open') || null
    let open = false
    if (!lastOpen) open = true
    else {
      const now = new Date()
      const lastOpenDate = new Date(lastOpen)
      lastOpenDate.setDate(lastOpenDate.getDate() + 1)
      if (lastOpenDate < now) {
        open = true
      }
    }
    if (open === true) {
      localstore.set('exc_dialog_last_open', new Date())
      this.$store.dispatch('player/closePersistantPlayer')
    }
    return {
      open,
      eventActive: false,
      eventCountdown: null,
    }
  },
  computed: {
    eventDate() {
      return new Date('2025-04-02T13:30:00.000Z')
    },
    eventSpan() {
      return 1 * 60 * 60 * 1000
    },
    isVisible() {
      return this.$route.name === 'index'
    },
  },
  watch: {
    open(status) {
      if (status === false) {
        this.$store.dispatch('player/openPersistantPlayer')
      }
    },
  },
  mounted() {
    this.computeEventCountdown()
    console.log(this.$voicer.getShellConfig('_event'))
  },
  methods: {
    handleClick() {
      this.open = false
      this.$emit('open-form')
    },
    computeEventCountdown() {
      if (!this.eventDate) {
        return false
      }
      const now = +new Date()
      const eventDate = this.eventDate
      const diff = Math.floor(eventDate - now)
      const diffEnd = diff + this.eventSpan
      if (diff < 0 && diffEnd < 0) {
        this.eventActive = false
        return
      }
      if (diff < 0 && diffEnd > 0) {
        this.eventActive = true
        this.eventCountdown = formatTimeCountdown(diffEnd / 1000)
      } else if (diff > 0) {
        this.eventActive = false
        this.eventCountdown = formatTimeCountdown(diff / 1000)
      }
      setTimeout(() => {
        this.computeEventCountdown()
      }, 500)
    },
  },
}
</script>

<style lang="scss">
.w-block__pre-featured__topblock {
  display: flex;
  justify-content: center;
  text-align: center;
}
.w-carglass-form-modal {
  &--mobile {
    display: flex;
    flex-direction: column;
    .v-card__text {
      flex-grow: 1;
      position: relative;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  &__body2 {
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  .body-2 {
    justify-content: center;
    text-align: center;
    align-items: center;
    display: inherit;
  }
  .image {
    justify-content: center;
    text-align: center;
    align-items: center;
  }
}
</style>
